const Soloution = () => {
  return (
    <div className="custom-text">
      <h2>About randomness</h2>
      <p>True random number generation <a href="https://en.wikipedia.org/wiki/Hardware_random_number_generator">(TRNG)</a> is hard for computers and is achieved by observing
        physical phenomenas around us. Computers tend to rely on Psuedo Random Number Generation  <a href="https://en.wikipedia.org/wiki/Pseudorandom_number_generator">(PRNG)</a>. Bits in decay relies on measuring nuclear decay which in physics
        is considered to be a random event. So when you want to create passwords or get a seed for your encryption TRNG is a more secure option.
      </p>
      <h3>The solution</h3>
      <p>The Bits in decay solution relies on a Geiger counter measuring beta and gamma radiation. When radiation is emitted
      the time is logged by the Geiger counter. The time difference between 2 radiation events is saved as (T1). Then the difference between the next pair is saved(T2).
      If T1 is less than T2 it's stored as 0, if T2 is less than T1 it's stored as 1. This is not a new solution, such solutions has been around for a long time for example
      with <a href="https://www.fourmilab.ch/hotbits/how3.html">Hotbits by Fourmilab</a>
      </p>
    </div>
  );
}

export default Soloution;

