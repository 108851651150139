import './App.css';
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home.js';
import Soloution from './components/solution.js';
import Header from './components/header.js';
import Trust from './components/trust.js';
import Result from './components/result.js';

function App() {
  
  let [ seedChoice, setSeedChoice ] = useState("pwd");

  let updateSeedChoice = (userSeedChoice) => {
    setSeedChoice(userSeedChoice);
  };

  return (
    <div className="d-flex h-100 text-center text-white bg-dark">
      <div className="cover-container h-100 d-flex p-3 mx-auto flex-column">
        <Header />
          <Routes>
            <Route path="/" exact element={<Home updateChoice={updateSeedChoice}/> }/>
            <Route path="/solution" element={<Soloution/>} />
            <Route path="/trust" element={<Trust/>}/>
            <Route path='/result' element={<Result userChoice={seedChoice} />}/>
          </Routes>
        <footer className="mt-auto text-white-50">
          <p>Under construction</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
