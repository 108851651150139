import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import NavBar from 'react-bootstrap/Navbar';

const Header = () => {
  return (
    <header className="mb-auto">
      <NavBar.Brand className="float-md-start mb-0">Random by radiation</NavBar.Brand>
      <Nav className="nav nav-masthead justify-content-center float-md-end">
        <NavLink to="/" className="nav-link">Home</NavLink>
        <NavLink to="/solution" className="nav-link">How does it work</NavLink>
        <NavLink to="/trust" className="nav-link">Trust</NavLink>
      </Nav>
    </header>
  );
}

export default Header;