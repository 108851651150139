const Trust = () => {
  return (
    <div className="custom-text">
      <h3>Trust me</h3>
      I guess you have to trust me since I don't provide a way for you to inspect the solution. This means that Bits in decay
        is probably most suitable for people that know me but I'll leave it up to you. That being said, I don't log any random numbers fetched and they are deleted
        when you request them. No numbers are backed up etc.
      <p/>
      <h3>Communication</h3>
      Upload of measurement data (radiation hit time stamps) is encrypted as well as when you request them. Currently the TLS connection
      you use to fetch random numbers is not setup with TRNG seeded by Bits in decay but is being worked on.
      <p />
      <h3>What about me?</h3>
      I totally understand that you care about me and is probably wondering if I expose myself to radiation just to bring you free true random numbers. I use
      a weak radiation source (potassium chloride) to get a little bit more hits than just background radiation. So I'm safe. Thank you for your concern &#128149;
      <p />
      <h3>Contact me</h3>
      I currently can't be contacted for now but is working on setting that up.
    </div>
  );
}

export default Trust;

