import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

const Home = (props) => {
  let updateChoice = ((userChoice) => {
    props.updateChoice(userChoice);
  });

  return (
    <div className="mb-auto">
      <main>
        <h1>Bits In Decay</h1>
        <p className="lead">True random numbers for you</p>
        <p className="lead">
          <Link to="/result">
            <Button onClick={() => updateChoice('password')} variant="primary" className="btn btn-sm btn-secondary fw-bold border-white bg-white">Get a randomized password</Button>
          </Link>
          <Link to="/result">
            <Button onClick={() => updateChoice('seed')} variant="primary" className="btn btn-sm btn-secondary fw-bold border-white bg-white">Get seed numbers</Button>
          </Link>
        </p>
      </main>
      <p className="text-center">
        <code className="custom-code-block">
          https://bid.omenstund.se/api/random/randomstring?numbersRequested=24
        </code>
        <br />
        <code className="custom-code-block">
          https://bid.omenstund.se/api/random/randomraw?numbersRequested=10
        </code>
      </p>
    </div>
  );
}

export default Home;