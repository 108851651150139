import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

const Result = (props) => {
  let url = 'https://bid.omenstund.se/api/random/randomstring?numbersRequested=24';
  
  if (props.userChoice === "seed") {
    url = 'https://bid.omenstund.se/api/random/randomraw?numbersRequested=10';
  }

  let [ decayData, setDecayData ] = useState([]);
  let [ isUpdated, setIsUpdated ] = useState(false);

  useEffect(() => {
    if (isUpdated === true) {
     return;
    }
    setIsUpdated(true);
    
    axios
    .get(`${url}`, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }})
      .then(
          response => {
            if(props.userChoice === "seed") {
              setDecayData(response.data);
            } else {
              setDecayData(response.data.result);
            }
      }).catch(function (error) {

        if (error.response.status === 429) {
          setDecayData("You're throttled, wait 30 sec and try again");
        } else {
          setDecayData("An error occured with status: " + error);
        }

    })
  },[decayData, isUpdated, url, props.userChoice]);

  return (
    <div className="result-text">
      { decayData }
      <p className="lead" />
      <Link to="/">
        <Button variant="primary" className="btn btn-sm btn-secondary fw-bold border-white bg-white">Home</Button>
      </Link>
    </div>
  );
}

export default Result;

